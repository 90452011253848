import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

const NotFoundPage = () => (
  <Layout classNameNav="bg-green-700 text-white">
    <Seo title="404: Not found" />
    <div className="h-screen flex flex-col mx-auto justify-center items-center">
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn't exist...</p>
    </div>
  </Layout>
)

export default NotFoundPage
